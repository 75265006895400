import React, { useContext } from "react";

import { star } from "assets/svg/star";
import { tick } from "assets/svg/tick";
import { LanguageContext } from "core/contexts/LanguageContext";
import { OfferCard } from "./OfferCard/OfferCard"; 



export function Pricing(props) {

  const t = useContext(LanguageContext).t;

  return(
    <section className="Pricing" id="Pricing">
      <div className="Pricing__inner">

        <div className="Pricing__header">
          <h2>{t("checkNow")}</h2>
          <h4>{t("bestProfessional")}</h4>
        </div>

        <div className="Pricing__offers">
          <OfferCard
            heading={t("remote")}
            price="20"
            content={
              [ [ tick, t("remote_1") ]
              , [ tick, t("remote_2") ]
              , [ tick, t("remote_3") ]
              , [ tick, t("remote_4") ]
              ]
            }
          />
          <OfferCard
            heading={t("homeDelivery")}
            price="35"
            content={
              [ [ star, t("home_1") ]
              , [ tick, t("home_2") ]
              , [ star, t("home_3") ]
              , [ tick, t("home_4") ]
              ]
            }
          />
        </div>

      </div>
    </section>
  )
}
import React, { useContext } from "react";

import { flagES } from "assets/svg/flags/flagES";
import { flagUK } from "assets/svg/flags/flagUK";
import { LanguageContext } from "core/contexts/LanguageContext";



export function LangNav(props){

  const changeLanguage = useContext(LanguageContext).changeLanguage;

  return(
    <div className="LangNav">
      <div className="LangNav__inner">
        <button onClick={() => changeLanguage("es")}>
          { flagES }
        </button>
        <button onClick={() => changeLanguage("en")}>
          { flagUK }
        </button>
      </div>
    </div>
  )
}



import React, { useContext } from "react";
 
import { Separator } from "core/components/Separator/Separator";
import { LanguageContext } from "core/contexts/LanguageContext";
import { YiChingHead     } from "./YiChingHead/YiChingHead";
import { YiChingIntro    } from "./YiChingIntro/YiChingIntro";
import { YiChingOccident } from "./YiChingOccident/YiChingOccident";
import { YiChingOracle   } from "./YiChingOracle/YiChingOracle";



export function YiChing(props) {

  const t = useContext(LanguageContext).t;

  return(
    <section className="YiChing" id="YiChing">
      <div className="YiChing__inner">

        <YiChingHead/>

        <div className="YiChing__header">
          <h1>{t("yiChing")}</h1>
          <h3>{t("theBookOfMutations")}</h3>
          <Separator/>
        </div>
        <YiChingIntro/>

        <div className="YiChing__header">
          <h3>{t("introductionInTheWest")}</h3>
          <Separator/>
        </div>  
        <YiChingOccident/>

        <div className="YiChing__header">
          <h3>{t("oracularUse")}</h3>
          <Separator/>
        </div>
        <YiChingOracle/>

      </div>
    </section>
  )
}

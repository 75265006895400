export const hexagramList = // ALWAYS FROM BOTTOM TO TOP
  [ [ "|||" , "|||" ]
  , [ "===" , "===" ]
  , [ "|==" , "=|=" ]
  , [ "=|=" , "==|" ]
  , [ "|||" , "=|=" ]
  , [ "=|=" , "|||" ]
  , [ "=|=" , "===" ]
  , [ "===" , "=|=" ]
  , [ "|||" , "=||" ]
  , [ "||=" , "|||" ]
  , [ "|||" , "===" ]
  , [ "===" , "|||" ]
  , [ "|=|" , "|||" ]
  , [ "|||" , "|=|" ]
  , [ "==|" , "===" ]
  , [ "===" , "|==" ]
  , [ "|==" , "||=" ]
  , [ "=||" , "==|" ]
  , [ "||=" , "===" ]
  , [ "===" , "=||" ]
  , [ "|==" , "|=|" ]
  , [ "|=|" , "==|" ]
  , [ "===" , "==|" ]
  , [ "|==" , "===" ]
  , [ "|==" , "|||" ]
  , [ "|||" , "==|" ]
  , [ "|==" , "==|" ]
  , [ "=||" , "||=" ]
  , [ "=|=" , "=|=" ]
  , [ "|=|" , "|=|" ]
  , [ "==|" , "||=" ]
  , [ "=||" , "|==" ]
  , [ "==|" , "|||" ]
  , [ "|||" , "|==" ]
  , [ "===" , "|=|" ]
  , [ "|=|" , "===" ]
  , [ "|=|" , "=||" ]
  , [ "||=" , "|=|" ]
  , [ "==|" , "=|=" ]
  , [ "=|=" , "|==" ]
  , [ "||=" , "==|" ]
  , [ "|==" , "=||" ]
  , [ "|||" , "||=" ]
  , [ "=||" , "|||" ]
  , [ "===" , "||=" ]
  , [ "=||" , "===" ]
  , [ "=|=" , "||=" ]
  , [ "=||" , "=|=" ]
  , [ "|=|" , "||=" ]
  , [ "=||" , "|=|" ]
  , [ "|==" , "|==" ]
  , [ "==|" , "==|" ]
  , [ "==|" , "=||" ]
  , [ "||=" , "|==" ]
  , [ "|=|" , "|==" ]
  , [ "==|" , "|=|" ]
  , [ "=||" , "=||" ]
  , [ "||=" , "||=" ]
  , [ "=|=" , "=||" ]
  , [ "||=" , "=|=" ]
  , [ "||=" , "=||" ]
  , [ "==|" , "|==" ]
  , [ "|=|" , "=|=" ]
  , [ "=|=" , "|=|" ]
  ]


export const hexagramLookup =
  hexagramList.map((x, i) => x.join(""))
import React, { useContext } from "react";

import { LanguageContext } from "core/contexts/LanguageContext";



export function YiChingHead(props) {

  const t = useContext(LanguageContext).t;

  return(
    <div className="YiChingHead">
      <h1 className="YiChingHead__title">
        {t("consultYiChing")}
      </h1>
      <h3 className="YiChingHead__subtitle">
        {t("tryYiChing1")}
      </h3>
      <h3 className="YiChingHead__subtitle">
        {t("tryYiChing2")}
      </h3>
      <h2 className="YiChingHead__cta">
        {t("anyProblem")}
        <br/>
        {t("consultTheOracle")}
        <br/>
        {t("iWillHelp")}
      </h2>
    </div>
  )
}
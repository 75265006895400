import React, { useContext } from "react";

import { LanguageContext } from "core/contexts/LanguageContext";



export function OfferCard(props) {

  const t = useContext(LanguageContext).t;

  const heading = props.heading;
  const price = props.price;
  const content = props.content;


  return(
    <div className="OfferCard">
      <h3 className="OfferCard__heading">
        {heading}
      </h3>
      <ul className="OfferCard__content">
        { content.map((c,i) =>
          <li key={i}>
            {c[0]}
            {c[1]}
          </li>
        )}
      </ul>
      <div className="OfferCard__price">
        <strong>{price}</strong>
        <span>€/{t("consultation")}</span>
        <br/>
        <em>{t("taxesIncluded")}</em>
      </div>
    </div>
  )
}
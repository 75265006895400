import React, { useContext, useEffect, useRef, useState } from "react";

import { LanguageContext } from "core/contexts/LanguageContext";



export function Collapse(props) {

  const buttonWithLine = props.buttonWithLine;

  const t = useContext(LanguageContext).t;

  const [isCollapsed, setCollapsed] = useState(true);
  const [maxH, setMaxH] = useState(0);
  const contentRef = useRef();


  useEffect( () => {
    const h = contentRef.current.scrollHeight;
    if (isCollapsed) {
      setMaxH(0);
    } else {
      setMaxH(h);
    }
  }
  , [isCollapsed])



  return(
    <div className="Collapse">
      <div 
        ref={contentRef}
        className="Collapse__content"
        style={{maxHeight: maxH}}
      >
        {props.children}
      </div>
      <div 
        className={
          "Collapse__buttonWrapper "
          + (buttonWithLine ? "Collapse__buttonWrapper--lined" : "")
        }
      >
        <button 
          className="Collapse__button" 
          type="button"
          onClick={() => setCollapsed(!isCollapsed)}
        >
          { isCollapsed
          ? t("expand")
          : t("contract")
          }
        </button>
      </div>
    </div>
  )
}


import { Shooter } from "core/components/Shooter/Shooter";
import { LanguageProvider } from 'core/contexts/LanguageContext';
import { BookLink } from "core/layout/BookLink/BookLink";
import { Header   } from "core/layout/Header/Header";
import { LangNav  } from "core/layout/LangNav/LangNav";
import { Contact } from "pages/home/Contact/Contact";
import { Pricing } from "pages/home/Pricing/Pricing";
import { YiChing } from "pages/home/YiChing/YiChing";


function App() {

  return (
    <div className="App">
      <LanguageProvider>
        <Shooter/>
        <Header/>
        <LangNav/>
        <main>
          <YiChing/>
          <Pricing/>
          <Contact/>
        </main>
        <BookLink/>
      </LanguageProvider>
    </div>
  );
}

export default App;

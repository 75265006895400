import React, { useContext } from "react";

import ichingImg from "assets/img/YiChing.jpg";
import { Collapse } from "core/components/Collapse/Collapse";
import { LanguageContext } from "core/contexts/LanguageContext";




export function YiChingIntro(props) {

  const t = useContext(LanguageContext).t;

  return(
    <div className="YiChingIntro">

      <div className="YiChingIntro__part YiChingIntro__part--left">
        <table className="YiChingIntro__technical">
          <tbody>
            <tr>
              <td>{t("nameTranslation")}</td>
              <td>{t("bookOfMutations")}</td>
            </tr>
            <tr>
              <td>{t("westernalized")}</td>
              <td>I Ching, Yijing, I King</td>
            </tr>
            <tr>
              <td>{t("traditionalChinese")}</td>
              <td>易經</td>
            </tr>
            <tr>
              <td>{t("simplifiedChinese")}</td>
              <td>易经</td>
            </tr>
            <tr>
              <td>Pinyin</td>
              <td>yì jīng</td>
            </tr>
            <tr>
              <td>{t("origin")}</td>
              <td>China, 1200 {t("beforeChrist")}</td>
            </tr>
            <tr>
              <td>{t("philosophy")}</td>
              <td>{t("taoistConfucianist")}</td>
            </tr>
            <tr>
              <td>{t("translatorGerman")}</td>
              <td>Richard Wilhelm</td>
            </tr>
            <tr>
              <td>{t("translatorEnglish")}</td>
              <td>Carl Gustav Jung</td>
            </tr>
            <tr>
              <td>{t("translatorSpanish")}</td>
              <td>D.J. Vogelmann</td>
            </tr>
          </tbody>
        </table>
        <figure>
          <img alt="Libro de las mutaciones" src={ichingImg}/>
          <figcaption>{t("theBookOfChanges")}</figcaption>
        </figure>         
      </div>

      <div className="YiChingIntro__part YiChingIntro__part--right">
        <Collapse buttonWithLine={true}>
        <p dangerouslySetInnerHTML={{__html: t("yiChingIntro_p1")}}>
        </p>
        <p dangerouslySetInnerHTML={{__html: t("yiChingIntro_p2")}}>
        </p>
        <p dangerouslySetInnerHTML={{__html: t("yiChingIntro_p3")}}>
        </p>
        <p>
          “Zhou Yi” {t("yiChingIntro_p4")}
        </p>
        </Collapse>
      </div>

    </div>
  )
}
import React from "react"

import { bookOpen } from "assets/svg/bookOpen"



export function BookLink() {

  return(
    <a
      className="BookLink"
      href="https://home-5013273754.app-ionos.space/"
      target="_blank"
      rel="noreferrer"
    >
      {bookOpen}
    </a>
  )
}
import React, { useContext } from "react";

import { document } from "assets/svg/document";
import { envelope } from "assets/svg/envelope";
import { taijitu  } from "assets/svg/taijitu";
import { LanguageContext } from "core/contexts/LanguageContext";



export function Nav(props) {

  const t = useContext(LanguageContext).t;

  return(
    <nav className="Nav">
      <a className="Nav__link" href="#YiChing">
        { taijitu }
        <strong>{t("yiChing")}</strong>
      </a>
      <a className="Nav__link" href="#Pricing">
        { document }
        <strong>{t("prices")}</strong>
      </a>
      <a className="Nav__link" href="#Contact">
        { envelope }
        <strong>{t("contact")}</strong>
      </a>
    </nav>
  )
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState} from "react";

import { hexagramList } from "core/components/Hexagram/hexagramLookup";
import { HexagramSvg  } from "core/components/Hexagram/HexagramSvg";
import { LanguageContext } from "core/contexts/LanguageContext";



export function Shooter(props){

  const t = useContext(LanguageContext).t;

  const [bullets, setBullets] = useState([]);
  const bulletsRef = useRef(bullets);
  bulletsRef.current = bullets;

  const [render, setRender] = useState(0);


  useEffect(() => {

    const mkBullet = () => {

      // const w = window.innerWidth  || document.documentElement.clientWidth  || document.body.clientWidth;
      // const h = document.body.scrollHeight;
      const w = 100;
      const h = 100;


      let hexN = 1 + Math.floor(Math.random() * 65);
      if (hexN >= 65) {hexN = 64}

      let x0 = Math.random() * w;
      if (x0 <=  3) {x0 =  3};
      if (x0 >= 97) {x0 = 97}

      let v0 = Math.random() * 10;
      if (v0 <= 1) {v0 = 1};


      return(
        { p0: 
          { x: x0
          , y: h
          }
        , t0: new Date().getTime()
        , v0: v0
        , hexN: hexN
        }
      )
    }

    if (Math.random() > 0.99) {
      setBullets([...bullets, mkBullet()])
      setRender(render + 1);
    }
  }, [render])



  useEffect(() => {
    const timer = setTimeout(() => {
      const currentT = new Date().getTime();
      setBullets(
        bulletsRef.current.filter(b => 
          currentT < (b.t0 + 120000)   &&   0 < calculatePosition(b).y
        )
      )
      if (render >= 10000) {
        setRender(0)
      } else {
        setRender(render + 1);
      }
    }, 20);
    return () => clearTimeout(timer);
  }, [render]);




  function calculatePosition(b) {

    const currentT = (new Date().getTime() - b.t0) / 90000
    return(
      { x: b.p0.x
      , y: b.p0.y * (1 - currentT * b.v0)
      })
  }

/*   function calculateWidth(b) {
    let res = 3;
    if (b.v0 >= 8) {res = 6}
    if (b.v0 < 8 && b.v0 >= 6) {res = 5}
    if (b.v0 < 6 && b.v0 >= 4) {res = 4}
    return res
  } */



  const renderBullet = (b,i) => {
    
    const {x,y} = calculatePosition(b);
    // const w = calculateWidth(b);
    const w = 2.5 + 1.2 * Math.log(b.v0);

    const trigrams = hexagramList[b.hexN - 1];
    const hexagramLines = Array.from(trigrams[0] + trigrams[1]);
    const concept = t("hex" + b.hexN);


    return(
    <div
      key={i}
      className="Shooter__bullet"
      style={{"--x": `${x}vw`, "--y": `${y}vh`, "--w": `${w}vw`}}
    >
      <div className="Shooter__bulletContent">
        <HexagramSvg
          withMargin={false}
          data={hexagramLines}
        />
        <p>{b.hexN + ". " + concept}</p>
      </div>
    </div>
  )
  }



  return(
    <div className="Shooter">
      {bullets.map(renderBullet)}
    </div>
  )
}
import React, { useContext } from "react";

import { LanguageContext } from "core/contexts/LanguageContext";



export function TrigramWheel(props) {

  const t = useContext(LanguageContext).t;
  const withShadow = props.withShadow;

  const y0 = 100
  const y1 = 220
  const y2 = 340
  const y3 = 460
  

  function octagon(y, tx, ty, fill) { 

    const d = y * Math.tan(Math.PI/8)

    return(
      <path
        fill={fill}
        stroke="none"
        transform={`translate(${tx}, ${ty})`}
        d={`
          M ${ 1 * y} ${-1 * d}
          L ${ 1 * d} ${-1 * y}
          L ${-1 * d} ${-1 * y}
          L ${-1 * y} ${-1 * d}
          L ${-1 * y} ${ 1 * d}
          L ${-1 * d} ${ 1 * y}
          L ${ 1 * d} ${ 1 * y}
          L ${ 1 * y} ${ 1 * d}
          Z
        `}
      />
    )
  }


  const taijitu =
    <g stroke="gray" strokeWidth={3}>
      <circle cx={0} cy={0} r={60} fill="white"/>
      <path 
        fill="gray" 
        d={`
          M  0 60 
          A 30 30 0 0 1 0 -60
          A 15 15 0 0 1 0   0
          A 15 15 0 0 0 0  60
        `}
      />
      <circle cx={0} cy={30}  r={10} fill="gray"  stroke="none"/>
      <circle cx={0} cy={-30} r={10} fill="white" stroke="none"/>
    </g>



  const column = () => {

    const f = y => y * Math.tan(Math.PI/8)

    return(
      <path
        strokeWidth={1}
        d={`
          M ${-1 * f(y0)} ${y0} 
          L ${ 1 * f(y0)} ${y0}
          L ${ 1 * f(y3)} ${y3}
          L ${-1 * f(y3)} ${y3}
          Z
          M ${-1 * f(y1)} ${y1}
          L ${ 1 * f(y1)} ${y1}
          M ${-1 * f(y2)} ${y2}
          L ${ 1 * f(y2)} ${y2}
        `}
      />
    )
  }



  const trigram = (a, y) => {

    const x0 = -40
    const x1 = -10
    const xM =   0
    const x2 =  10
    const x3 =  40
    const y0 = y - 15
    const y1 = y
    const y2 = y + 15

    const yin1 = a === 0 | a === 225 | a === 270 | a === 315
    const yin2 = a === 0 | a ===  45 | a ===  90 | a === 315
    const yin3 = a === 0 | a ===  45 | a === 135 | a === 270

    const line = (yin, y) =>
      `
      M ${x0}             ${y}
      L ${yin ? x1 : xM} ${y}
      M ${yin ? x2 : xM} ${y}
      L ${x3}             ${y}
      `

    return(
      <path
        fill="none"
        stroke="dimgray"
        strokeWidth={7}
        d={`
          ${line(yin1, y0)}
          ${line(yin2, y1)}
          ${line(yin3, y2)}
        `}
      />
    )
  }



  const coreText = (a) => {

    let res = []
    switch (a) {
      case  45: res=["SHEN"  , t("shen_concept") , t("shen_image") ]; break;
      case  90: res=["LI"    , t("li_concept")   , t("li_image")   ]; break;
      case 135: res=["TUI"   , t("tui_concept")  , t("tui_image")  ]; break;
      case 180: res=["CH'IEN", t("chien_concept"), t("chien_image")]; break;
      case 225: res=["SUN"   , t("sun_concept")  , t("sun_image")  ]; break;
      case 270: res=["K'AN"  , t("kan_concept")  , t("kan_image")  ]; break;
      case 315: res=["GEN"   , t("gen_concept")  , t("gen_image")  ]; break;
      default : res=["K'UN"  , t("kun_concept")  , t("kun_image")  ]; break;
    }

    return res
  }



  const moreText = (a) => {

    let res = []
    switch (a) {
      case  45: res=[t("shen_family") , t("shen_quality") , t("shen_season") ]; break;
      case  90: res=[t("li_family")   , t("li_quality")   , t("li_season")   ]; break;
      case 135: res=[t("tui_family")  , t("tui_quality")  , t("tui_season")  ]; break;
      case 180: res=[t("chien_family"), t("chien_quality"), t("chien_season")]; break;
      case 225: res=[t("sun_family")  , t("sun_quality")  , t("sun_season")  ]; break;
      case 270: res=[t("kan_family")  , t("kan_quality")  , t("kan_season")  ]; break;
      case 315: res=[t("gen_family")  , t("gen_quality")  , t("gen_season")  ]; break;
      default : res=[t("kun_family")  , t("kun_quality")  , t("kun_season")  ]; break;
    }

    return res
  }



  const printText = (a, y, ts) => {

    return(
      <text 
        textAnchor="middle" dominantBaseline="middle" 
        fontSize={24} fontFamily="monospace" fill="dimgray" stroke="none"
        transform={
          (a > 90 && a < 270)
          ? `translate(0 ${y}) rotate(180 0 0)`
          : `translate(0 ${y})`
        }
      >
        <tspan fontWeight={900} x={0} y={-30}>{ts[0]}</tspan>
        <tspan fontWeight={700} x={0} y={  0}>{ts[1]}</tspan>
        <tspan fontWeight={500} x={0} y={ 30}>{ts[2]}</tspan>
      </text>
    )
  }


  const trigramColumn = (angle) => {
    return(
      <g transform={`rotate(${angle} 0 0)`}>
        {column(angle)}
        {trigram(angle, 160)}
        {printText(angle, 280, coreText(angle))}
        {printText(angle, 400, moreText(angle))}
      </g>
    )
  }

  return(
    <svg 
      className="YiChingOracle__trigrams" 
      viewBox="-500 -500 1000 1000" 
      preserveAspectRatio="xMidYMid meet"
    >
      { withShadow 
      ? octagon(y3, 12, 30, "rgba(0,0,0,0.1)")
      : <></>}
      {octagon(y0, 0, 0)}
      {trigramColumn(0)}
      {trigramColumn(45)}
      {trigramColumn(90)}
      {trigramColumn(135)}
      {trigramColumn(180)}
      {trigramColumn(225)}
      {trigramColumn(270)}
      {trigramColumn(315)}
      {taijitu}
    </svg>
  )
}
import React, { useContext } from "react";

import { Separator    } from "core/components/Separator/Separator";
import { LanguageContext } from "core/contexts/LanguageContext";



export function Contact(props) {

  const t = useContext(LanguageContext).t;


  return(
    <section className="Contact" id="Contact">
    <div className="Contact__inner">

      <div className="Contact__header">
        <h1>{t("contactMe")}</h1>
        <Separator/>
      </div>

      <h3 className="Contact__mail">
        ramir659@icloud.com
      </h3>

    </div>
    </section>
  )
}
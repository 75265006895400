export const en =
{ "code200": "Thank you for contacting, I will respond with the greatest haste."
, "code400": "The form data is invalid."
, "code405": "Only POST requests are allowed."
, "code500": "Internal server error."
, "requiredError": "{{field}} is required"
, "invalidFormatError": "Invalid format"
, "tooShortError": "{{field}} too short. Minimun length is {{minlen}} characters"
, "tooLongError": "{{field}} too long. Maximun length is {{maxlen}} characters"
, "notAlphanumError": "{{field}} may only contain alphanumeric characters or single hyphens, and cannot begin or end with a hyphen" 

, "oracle": "oracle"
, "of": "of"
, "yiChing": "Yi Ching"
, "consultYiChing": "Consult the Yi Ching"
, "tryYiChing1": "Try the millenary wisdom"
, "tryYiChing2": "of the Book of Changes"
, "anyProblem": "Do you have a problem in your life?"
, "consultTheOracle": "Consult the Yi Ching's oracle"
, "iWillHelp": "I will help you understand the result!"
, "prices": "prices"
, "contact": "contact"
, "expand": "expand"
, "contract": "contract"

, "theBookOfMutations": "the book of mutations"
, "theBookOfChanges": "The Book of Changes"
, "introductionInTheWest": "Introduction in the West"
, "oracularUse": "Oracular Use"
, "nameTranslation": "Name translation"
, "bookOfMutations": "Book of Mutations"
, "westernalized": "Westernalized"
, "traditionalChinese": "Traditional Chinese"
, "simplifiedChinese": "Simplified Chinese"
, "origin": "Origin"
, "beforeChrist": "b.C."
, "philosophy": "Phylosophy"
, "taoistConfucianist": "Taoist, Confucianist"
, "translatorGerman": "German T."
, "translatorEnglish": "English T."
, "translatorSpanish": "Spanish T."

, "yiChingIntro_p1": "The <b>Yi Ching</b> is an oracular, sapiential and moral book of ancient China, and one of the Five Confucian Classics. The term Yi Ching translates to 'book of mutations' or 'book of changes'. Like the Bible, it is a collection of books. The first texts are dated around 1200 B.C. but the spiritual wisdom that crystallized in those early texts is even earlier."
, "yiChingIntro_p2": "The remote precursors of Taoism (or a single precursor, the mythical Fu Hsi) intuited the cyclically infinite sequence of the 64 signs. The Fathers of Taoism (Lao Tzu, Zhuang Tzu, Lie Tse) and the first monarch of the Zhou Dynasty, <b>King Wen</b>, collected the 64 signs and assigned them their Judgements."
, "yiChingIntro_p3": "Subsequently, King Wen's son, the <b>Duke of Zhou</b>, increased the texts with explanations for The Different Lines, and later the philosophers of the <b>Confucius</b> school (Kung Tse) added their own commentaries, but the origin is Taoist."
, "yiChingIntro_p4": "is the abbreviated title of this book in Chinese: “The Mutations of the Zhou”, because 周 is Zhou, 易 is Yi (the ideograms on the cover)"
, "yiChingWest_p11": "The introduction of the I Ching in the West is very recent, compared to the age of the book. It is known that <b>Leibniz</b> himself, German &quot;universal genius&quot;, father of differential and integral calculus and prominent figure of Mathematics and Philosophy, had contact with the structure of the I Ching through his epistolary relationship (by letter) with Father Bouvet, a missionary in Beijing."
, "yiChingWest_p12": "Already in 1703 Leibniz declares his surprise at the total mathematical coincidence of the structure and order of the hexagrams of the I Ching with the binary number system devised by him for different purposes. This happened 130 years before a first version of the I Ching, published in Latin by the Jesuit priest P. Regis, became known in Europe."
, "yiChingWest_p21": "The translation considered canonical came to the West from the hand of the German sinologist and theologian Richard Wilhelm, already in the XX century. Wilhelm traveled to China as a missionary, and devoted many years of his adult life to this work, imbuing himself with Chinese culture and scholarship. Hand in hand with his revered teacher Lao Nai Süan, he immersed himself in the works of Mencius (Mong Tzu), the philosophy of yoga and the mysteries of the Book of Mutations."
, "yiChingWest_p22": "Under the expert guidance of his teacher, he carried out the translation of the I Ching into German, through a meticulous process of back and forth between both languages to obtain a presentation of the meaning of the text complete and free of inaccuracies. Such process was stopped by the horror of the World War, when he was separated from master Lao. However, Wilhelm was able to combine his dedication to ancient Chinese wisdom with his responsibilities as director of the Chinese Red Cross during the siege of Tsingtao."
, "yiChingWest_p23": "He returned to Germany briefly, and returned to China to finish in 1923 the translation of this monumental work of Chinese thought. Wilhelm's German version has served as a canonical reference to obtain various translations in English, Italian or Spanish among others."
, "yiChingWest_p31": "But the figure who undoubtedly raises the prestige of the Yi Ching in the Western world is the Swiss psychologist and psychoanalyst <b>Carl Gustav Jung</b>, considered one of the fathers of psychoanalysis along with Sigmund Freud himself, with whom he collaborated for several years before their differences in theoretical views led them to a breakdown of the relationship."
, "yiChingWest_p32": "Jung was a personal friend of Richard Wilhelm, who put him in touch with the Book of Changes. Jung himself was in charge of translating the book into English, including a prologue from his own hand that is enormously revealing. From this prologue it follows that Jung had vast experience with the oracular use of the book, and several practical examples of such use can be read by Jung while he was writing his prologue."
, "yiChingWest_p33": "The prologue also reads that Jung considers the I Ching as a <b>tool of exceptional value for psychoanalysis</b>. It also contrasts the way of thinking of the Western world with the Eastern mentality:"
, "yiChingWest_p34": "The modern Western mind is obsessed with seeking causality and pretends to ignore random events, a futile struggle against chance, since as soon as experiments leave the controlled conditions of the laboratory and go into the real world, they become subject to unforeseen and random events."
, "yiChingWest_p35": "On the other hand, the Eastern mentality does not care about the causes, and focuses its interest on collecting every detail of the observed moment. Every detail, however minuscule, is an essential part of the moment. Jung called it <b>Synchronicity</b>."
, "yiChingOracle_p1": "In my personal experience, a useful result can be obtained from the oracle even without asking a question. However, it is advisable to ask, since the mere act of asking helps to obtain an answer, <em>every clear question carries in itself the answer</em>. Note that the oracle cannot answer 'yes' or 'no' directly, but contrary to what it may seem, this is an advantage, since you get a much more informative answer."
, "yiChingOracle_p2": "The consultation can be done by the method of yarrow stems or with <b>three coins</b>, this second method being considerably simpler than the first. After a simple coin-tosing process (which I will monitor with full attention), you will get one of the 64 possible hexagrams, and I will help you interpret the result."

, "checkNow": "Consult Now!"
, "bestProfessional": "with the best professional"
, "consultation": "consultation"
, "taxesIncluded": "taxes included"
, "remote": "remote"
, "homeDelivery": "home delivery"
, "remote_1": "Fast and simple, no waiting nor protocol."
, "remote_2": "From the comfort of your home."
, "remote_3": "You choose the channel for the consultation."
, "remote_4": "Absolute confidentiality, guaranteed."
, "home_1": "Optimal quality of service (body language)."
, "home_2": "From the comfort of your home."
, "home_3": "You will be able to observe it and live it closely."
, "home_4": "Discount for groups."

, "contactMe": "Contact Me"
, "name": "Name"
, "contactMethod": "Contact method (email, telephone...)"
, "secretMessage": "Secret Message"
, "send": "SEND"

// TRIGRAMS
, "chien_concept": "Creative"
, "chien_image": "sky"
, "chien_quality": "is strong"
, "chien_family": "father"
, "chien_season": "Summer"

, "kun_concept": "Receptive"
, "kun_image": "earth"
, "kun_quality": "is selfless"
, "kun_family": "mother"
, "kun_season": "Winter"

, "shen_concept": "Arousing"
, "shen_image": "thunder"
, "shen_quality": "is mobilizing"
, "shen_family": "1st son"
, "shen_season": ""

, "kan_concept": "Abyssal"
, "kan_image": "water"
, "kan_quality": "is dangerous"
, "kan_family": "2nd son"
, "kan_season": "Autumn"

, "gen_concept": "Immobile"
, "gen_image": "mountain"
, "gen_quality": "is still"
, "gen_family": "3rd son"
, "gen_season": ""

, "sun_concept": "Smooth"
, "sun_image": "wind"
, "sun_quality": "is penetrating"
, "sun_family": "1st daughter"
, "sun_season": ""

, "li_concept": "Adherent"
, "li_image": "fire"
, "li_quality": "is luminous"
, "li_family": "2nd daughter"
, "li_season": "Spring"

, "tui_concept": "Serene"
, "tui_image": "lake"
, "tui_quality": "is rejoicing"
, "tui_family": "3rd daughter"
, "tui_season": ""

// HEXAGRAMS
, "hex1": "The Creative"
, "hex2": "The Receptive"
, "hex3": "Difficulty at the Beginning"
, "hex4": "Youthful Folly"
, "hex5": "Waiting"
, "hex6": "Conflict"
, "hex7": "The Army"
, "hex8": "Solidarity"
, "hex9": "The Taming Power of the Small"
, "hex10": "Treading"
, "hex11": "Peace"
, "hex12": "Stagnation"
, "hex13": "Fellowship with Men"
, "hex14": "The Great Possession"
, "hex15": "Modesty"
, "hex16": "Enthusiasm"
, "hex17": "Following"
, "hex18": "Work on what has been Spoiled"
, "hex19": "Nearing"
, "hex20": "Contemplation"
, "hex21": "Gnawing Bite"
, "hex22": "Grace"
, "hex23": "Splitting Apart"
, "hex24": "The Turning Point"
, "hex25": "Innocence"
, "hex26": "The Taming Power of the Great"
, "hex27": "The Corners of the Mouth"
, "hex28": "Preponderance of the Great"
, "hex29": "The Abyssal, Water"
, "hex30": "The Adherent, Fire"
, "hex31": "Influence"
, "hex32": "Duration"
, "hex33": "Retreat"
, "hex34": "The Power of the Great"
, "hex35": "Progress"
, "hex36": "Darkening of the Light"
, "hex37": "The Clan"
, "hex38": "Opposition"
, "hex39": "Obstruction"
, "hex40": "Taking Apart"
, "hex41": "Diminishing"
, "hex42": "Augmenting"
, "hex43": "Displacement"
, "hex44": "Coming to Meet"
, "hex45": "Gathering Together"
, "hex46": "Ascending"
, "hex47": "Exhaustion"
, "hex48": "The Well"
, "hex49": "Revolution"
, "hex50": "The Cauldron"
, "hex51": "The Arousing"
, "hex52": "Keeping Still"
, "hex53": "Evolution"
, "hex54": "The Marrying Maiden"
, "hex55": "Fullness"
, "hex56": "The Wanderer"
, "hex57": "The Smooth, Wind"
, "hex58": "The Joyous, Lake"
, "hex59": "Dissolution"
, "hex60": "Limitation"
, "hex61": "Inner Truth"
, "hex62": "Preponderance of the Small"
, "hex63": "After Completion"
, "hex64": "Before Completion"
}
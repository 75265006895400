import React, { useContext } from "react";

import jungImg    from "assets/img/Jung.jpg";
import leibnizImg from "assets/img/Leibniz.jpg";
import wilhelmImg from "assets/img/RichardWilhelm.jpg";
import { Collapse } from "core/components/Collapse/Collapse";
import { LanguageContext } from "core/contexts/LanguageContext";



export function YiChingOccident(props){

  const t = useContext(LanguageContext).t;

  return(
    <div className="YiChingOccident">

      <div className="YiChingOccident__section">
        <figure>
          <img alt="Gottfried Wilhelm Leibniz" src={leibnizImg}/>
          <figcaption>Gottfried Wilhelm Leibniz</figcaption>
        </figure>
        <Collapse buttonWithLine={true}>
        <div className="YiChingOccident__sectionInfo">
          <p dangerouslySetInnerHTML={{__html: t("yiChingWest_p11")}}>
          </p>
          <p>
            {t("yiChingWest_p12")}
          </p>
        </div>
        </Collapse>
      </div>

      <div className="YiChingOccident__section">
        <figure>
          <img alt="Richard Wilhelm" src={wilhelmImg}/>
          <figcaption>Richard Wilhelm</figcaption>
        </figure>
        <Collapse buttonWithLine={true}>
        <div className="YiChingOccident__sectionInfo">
          <p dangerouslySetInnerHTML={{__html: t("yiChingWest_p21")}}>
          </p>
          <p>
            {t("yiChingWest_p22")}
          </p>
          <p>
            {t("yiChingWest_p23")}
          </p>
        </div>
        </Collapse>
      </div>

      <div className="YiChingOccident__section">
        <figure>
          <img alt="Carl Gustav Jung" src={jungImg}/>
          <figcaption>Carl Gustav Jung</figcaption>
        </figure>
        <Collapse buttonWithLine={true}>
        <div className="YiChingOccident__sectionInfo">
          <p dangerouslySetInnerHTML={{__html: t("yiChingWest_p31")}}>
          </p>
          <p>
            {t("yiChingWest_p32")}
          </p>
          <p dangerouslySetInnerHTML={{__html: t("yiChingWest_p33")}}>
          </p>
          <p>
            {t("yiChingWest_p34")}
          </p>
          <p dangerouslySetInnerHTML={{__html: t("yiChingWest_p35")}}>
          </p>
        </div>
        </Collapse>
      </div>

    </div>
  )
}
import React from 'react';

export const taijitu = 
<svg viewBox="-1 -1 2 2" height="100px" width="100px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
        <circle cx="0.0" cy="0.0" r="0.92" fill="white" />
        <path fill="black" d="M -0.92,0.0 A 0.46,0.46 0.0 1,0 0.0,0.0 A 0.46,0.46 0.0 1,1 0.92,0.0 A 0.92,0.92 0.0 1,1 -0.92,0.0 Z" />
        <circle cx="0.46" cy="0.0" r="0.15333334" stroke="none" fill="white" />
        <circle cx="-0.46" cy="0.0" r="0.15333334" stroke="none" fill="black" />
    </g>
</svg>

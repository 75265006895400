import React from 'react';

export const document = 
<svg viewBox="-1 -1 2 2" height="100px" width="100px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
        <path strokeLinejoin="round" strokeLinecap="round" fill="none" d="M 0.5871,-0.16 L 0.5871,-0.95 L -0.5871,-0.95 L -0.5871,0.95 L 0.5871,0.95 L 0.5871,0.7 " />
        <path strokeLinecap="round" d="M -0.4,-0.65 L 0.4,-0.65 M -0.4,-0.35 L 0.4,-0.35 M -0.4,-5.0e-2 L 0.4,-5.0e-2 " />
        <path d="M -0.15,0.0 L 0.15,0.0 M 0.0,-0.15 L 0.0,0.15 " fill="none" strokeLinecap="round" transform="translate(-0.25 0.55)rotate(45,0,0)" />
        <path d="M 0.0,0.7899999999999999 L 0.0,0.94 L -0.3,0.4 L 0.3,0.4 L 0.0,0.94 M -0.3,0.4 L -0.3,-0.6 L 0.3,-0.6 L 0.3,0.4 M 0.0,0.4 L 0.0,-0.6 M -0.3,-0.6 L -0.3,-0.76 L 0.3,-0.76 L 0.3,-0.6 M -0.3,-0.76 L -0.3,-0.9 A 0.3,3.0e-2 0.0 1,1 0.3,-0.9 L 0.3,-0.76 " strokeLinejoin="round" transform="translate(0.55 0.35)rotate(45,0,0)scale(0.4 0.4)" />
    </g>
</svg>

export const es =
{ "code200": "Gracias por contactar, responderé con la mayor premura."
, "code400": "Los datos del formulario son inválidos."
, "code405": "Solo se permiten peticiones POST."
, "code500": "Error interno del servidor."
, "requiredError": "El campo {{field}} es obligatorio"
, "invalidFormatError": "Formato inválido"
, "tooShortError": "El campo {{field}} es demasiado corto. La longitud mínima es {{minlen}} caracteres"
, "tooLongError": "El campo {{field}} es demasiado largo. La longitud máxima es {{maxlen}} caracteres"
, "notAlphanumError": "El campo {{field}} solo puede contener carácteres alfanuméricos o guiones simples, y no puede empezar o terminar en guión"

, "oracle": "oráculo"
, "of": "del"
, "yiChing": "I Ching"
, "consultYiChing": "Consulta el I Ching"
, "tryYiChing1": "Prueba la sabiduría milenaria"
, "tryYiChing2": "del Libro de los Cambios"
, "anyProblem": "¿Tienes algún problema en tu vida?"
, "consultTheOracle": "Consulta el oráculo del I Ching"
, "iWillHelp": "¡Yo te ayudaré a interpretar el resultado!"
, "prices": "precios"
, "contact": "contacto"
, "expand": "expandir"
, "contract": "contraer"

, "theBookOfMutations": "el libro de las mutaciones"
, "theBookOfChanges": "El Libro de las Mutaciones"
, "introductionInTheWest": "Introducción en Occidente"
, "oracularUse": "Uso Oracular"
, "nameTranslation": "T. del nombre"
, "bookOfMutations": "Libro de las Mutaciones"
, "westernalized": "Occidentalizado"
, "traditionalChinese": "Chino tradicional"
, "simplifiedChinese": "Chino simplificado"
, "origin": "Origen"
, "beforeChrist": "a.C."
, "philosophy": "Filosofía"
, "taoistConfucianist": "Taoísta, Confucianista"
, "translatorGerman": "T. alemán"
, "translatorEnglish": "T. inglés"
, "translatorSpanish": "T. español"

, "yiChingIntro_p1": "El <b>I Ching</b> es un libro oracular, sapiencial y moral de la antigua China, y uno de los Cinco Clásicos confucianos. El término I Ching se traduce como 'libro de las mutaciones' o 'libro de los cambios'. Al igual que la Biblia, es una colección de libros. Los primeros textos se fechan en torno al 1200 a.C. pero la sabiduría espiritual que cristalizó en esos primeros textos es incluso anterior."
, "yiChingIntro_p2": "Los remotos precursores del taoísmo (o bien un único precursor, el mítico Fu Hsi) intuyeron la secuencia cíclicamente infinita de los 64 signos. Los Padres del Taoísmo (Lao Tse, Zhuang Tse, Lie Tse) y el primer monarca de la dinastía Zhou, el <b>rey Wen</b>, recopilaron los 64 signos y les asignaron sus Dictámenes."
, "yiChingIntro_p3": "Posteriormente, el hijo del rey Wen, el <b>duque de Zhou</b>, aumentó los textos con explicaciones para Las Diferentes Líneas, y más tarde los filósofos de la escuela de <b>Confucio</b> (Kung Tse) añadieron sus propios comentarios, pero el origen es taoísta."
, "yiChingIntro_p4": "es el título abreviado de este libro en chino: “Las Mutaciones de los Zhou”, pues 周 es Zhou, 易 es Yi (los ideogramas de la portada)"
, "yiChingWest_p11": "La introducción del I Ching en Occidente es muy reciente, comparada con la edad del libro. Se sabe que el mismo <b>Leibniz</b>, &quot;genio universal&quot; alemán, padre del cálculo diferencial e integral y figura prominente de la Matemática y la Filosofía, tuvo contacto con la estructura del I Ching a través de su relación epistolar (por carta) con el Padre Bouvet, misionero en Pekín."
, "yiChingWest_p12": "Ya en 1703 Leibniz declara su sorpresa frente a la total coincidencia matemática de la estructura y orden de los hexagramas del I Ching con el sistema numérico binario ideado por él para fines diferentes. Esto sucedió 130 años antes de conocerse en Europa una primera versión del I Ching, publicada en latín por el sacerdote jesuita P. Regis."
, "yiChingWest_p21": "La traducción considerada canónica llegó a Occidente de la mano del sinólogo y teólogo alemán <b>Richard Wilhelm</b>, ya en el siglo XX. Wilhelm viajó a China como misionero, y dedicó muchos años de su vida adulta a esa labor, imbuyéndose de la cultura y erudición chinas. De la mano de su venerado maestro Lao Nai Süan, se sumergió en las obras de Mencio (Mong Tse), la filosofía del yoga y los misterios del Libro de las Mutaciones."
, "yiChingWest_p22": "Bajo la experta conducción de su maestro, llevó a cabo la traducción del I Ching al alemán, mediante un meticuloso proceso de ida y vuelta entre ambos idiomas para obtener una presentación del sentido del texto cabal y exenta de inexactitudes. Tal proceso se detuvo por el horror de la Guerra Mundial, al verse separado del maestro Lao. Sin embargo, Wilhelm pudo compaginar su dedicación a la antigua sabiduría china con sus responsabilidades como director de la Cruz Roja china durante el asedio de Tsingtao."
, "yiChingWest_p23": "Regresó a Alemania brevemente, y volvió a China para finalizar en 1923 la traducción de esta obra monumental del pensamiento chino. La versión en alemán de Wilhelm ha servido como referencia canónica para obtener diversas traducciones en inglés, italiano o español entre otros."
, "yiChingWest_p31": "Pero la figura que eleva sin duda el prestigio del I Ching en el mundo occidental es el psicólogo y psicoanalista suizo <b>Carl Gustav Jung</b>, considerado uno de los padres del psicoanálisis junto con el propio Sigmund Freud, con el que colaboró durante varios años antes de que sus diferencias en los puntos de vista teóricos les condujeran a una ruptura de la relación."
, "yiChingWest_p32": "Jung fue amigo personal de Richard Wilhelm, quien le puso en contacto con el Libro de los Cambios. El mismo Jung se encargó de traducir el libro al inglés, incluyendo un prólogo de su mano y letra que es enormemente revelador. De dicho prólogo se deduce que Jung tuvo una vasta experiencia con el uso oracular del libro, y se pueden leer varios ejemplos prácticos de dicho uso llevados a cabo por Jung mientras escribía su prólogo."
, "yiChingWest_p33": "En el prólogo se lee también que Jung considera el I Ching como una <b>herramienta de valor excepcional para el psicoanálisis</b>. También contrapone el modo de pensar del mundo occidental a la mentalidad oriental:"
, "yiChingWest_p34": "La mente occidental moderna se obsesiona por buscar la causalidad y pretende ignorar los eventos azarosos, una lucha inútil contra el azar, ya que tan pronto los experimentos salen de las condiciones controladas del laboratorio al mundo real, quedan sujetos a imprevistos y eventos aleatorios."
, "yiChingWest_p35": "Por contra, la mentalidad oriental no se preocupa por las causas, y centra su interés en recoger cada detalle del momento observado. Cada detalle, por minúsculo que sea, es parte esencial del momento. Jung lo denominó <b>Sincronicidad</b>."
, "yiChingOracle_p1": "Según mi experiencia personal, se puede obtener un resultado útil del oráculo incluso sin formular una pregunta. No obstante, es recomendable formular una pregunta, ya que solo el hecho de formularla ayuda a obtener una respuesta, <em>toda pregunta clara lleva en sí misma la respuesta</em>. Nótese que el oráculo no puede contestar 'sí' ni 'no' directamente, pero contrariamente a lo que puede parecer, esto es una ventaja, ya que se obtiene una respuesta mucho más informativa."
, "yiChingOracle_p2": "La consulta se puede hacer mediante el método de los tallos de milenrama o mediante <b>tres monedas</b>, siendo este segundo método considerablemente más sencillo que el primero. Después de un proceso sencillo de lanzamiento de monedas (el cual supervisaré con plena atención), usted obtendrá uno de los 64 posibles hexagramas, y yo le ayudaré a interpretar el resultado."

, "checkNow": "¡Consulta Ya!"
, "bestProfessional": "con el mejor profesional"
, "consultation": "consulta"
, "taxesIncluded": "IVA incluido"
, "remote": "en remoto"
, "homeDelivery": "a domicilio"
, "remote_1": "Rápido y sencillo, sin esperas ni protocolo."
, "remote_2": "Desde la comodidad de tu casa."
, "remote_3": "Tú eliges el canal para realizar las consultas."
, "remote_4": "Confidencialidad absoluta, garantizada."
, "home_1": "Calidad del servicio óptima (lenguaje corporal)."
, "home_2": "Desde la comodidad de tu casa."
, "home_3": "Podrás observarlo y vivirlo de cerca."
, "home_4": "Descuento para grupos."

, "contactMe": "Contáctame"
, "name": "Nombre"
, "contactMethod": "Forma de contacto (email, teléfono...)"
, "secretMessage": "Mensaje Secreto"
, "send": "ENVIAR"

// TRIGRAMS
, "chien_concept": "Lo Creativo"
, "chien_image": "cielo"
, "chien_quality": "es fuerte"
, "chien_family": "padre"
, "chien_season": "verano"

, "kun_concept": "Lo Receptivo"
, "kun_image": "tierra"
, "kun_quality": "es abnegado"
, "kun_family": "madre"
, "kun_season": "invierno"

, "shen_concept": "Lo Suscitativo"
, "shen_image": "trueno"
, "shen_quality": "es movilizante"
, "shen_family": "hijo 1º"
, "shen_season": ""

, "kan_concept": "Lo Abismal"
, "kan_image": "agua"
, "kan_quality": "es peligroso"
, "kan_family": "hijo 2º"
, "kan_season": "otoño"

, "gen_concept": "Lo Inmóvil"
, "gen_image": "montaña"
, "gen_quality": "es quieto"
, "gen_family": "hijo 3º"
, "gen_season": ""

, "sun_concept": "Lo Suave"
, "sun_image": "viento"
, "sun_quality": "es penetrante"
, "sun_family": "hija 1ª"
, "sun_season": ""

, "li_concept": "Lo Adherente"
, "li_image": "fuego"
, "li_quality": "es luminoso"
, "li_family": "hija 2ª"
, "li_season": "primavera"

, "tui_concept": "Lo Sereno"
, "tui_image": "lago"
, "tui_quality": "es regocijante"
, "tui_family": "hija 3ª"
, "tui_season": ""

// HEXAGRAMS
, "hex1": "Lo Creativo"
, "hex2": "Lo Receptivo"
, "hex3": "La Dificultad Inicial"
, "hex4": "La Necedad Juvenil"
, "hex5": "La Espera"
, "hex6": "El Conflicto"
, "hex7": "El Ejército"
, "hex8": "La Solidaridad"
, "hex9": "La Fuerza Domesticadora de lo Pequeño"
, "hex10": "El Porte"
, "hex11": "La Paz"
, "hex12": "El Estancamiento"
, "hex13": "La Comunidad con los Hombres"
, "hex14": "La Posesión de lo Grande"
, "hex15": "La Modestia"
, "hex16": "El Entusiasmo"
, "hex17": "El Seguimiento"
, "hex18": "El Trabajo en lo Echado a Perder"
, "hex19": "El Acercamiento"
, "hex20": "La Contemplación"
, "hex21": "La Mordedura Tajante"
, "hex22": "La Gracia"
, "hex23": "La Desintegración"
, "hex24": "El Retorno"
, "hex25": "La Inocencia"
, "hex26": "La Fuerza Domesticadora de lo Grande"
, "hex27": "Las Comisuras de la Boca"
, "hex28": "La Preponderancia de lo Grande"
, "hex29": "Lo Abismal, El Agua"
, "hex30": "Lo Adherente, El Fuego"
, "hex31": "El Influjo"
, "hex32": "La Duración"
, "hex33": "La Retirada"
, "hex34": "El Poder de lo Grande"
, "hex35": "El Progreso"
, "hex36": "El Oscurecimiento de la Luz"
, "hex37": "El Clan"
, "hex38": "El Antagonismo"
, "hex39": "El Impedimento"
, "hex40": "La Liberación"
, "hex41": "La Merma"
, "hex42": "El Aumento"
, "hex43": "El Desbordamiento"
, "hex44": "El Ir al Encuentro"
, "hex45": "La Reunión"
, "hex46": "La Subida"
, "hex47": "La Desazón"
, "hex48": "El Pozo de Agua"
, "hex49": "La Revolución"
, "hex50": "El Caldero"
, "hex51": "Lo Suscitativo"
, "hex52": "El Aquietamiento"
, "hex53": "La Evolución"
, "hex54": "La Muchacha que se Casa"
, "hex55": "La Plenitud"
, "hex56": "El Andariego"
, "hex57": "Lo Suave, El Viento"
, "hex58": "Lo Sereno, El Lago"
, "hex59": "La Disolución"
, "hex60": "La Restricción"
, "hex61": "La Verdad Interior"
, "hex62": "La Preponderancia de lo Pequeño"
, "hex63": "Después de la Consumación"
, "hex64": "Antes de la Consumación"
}
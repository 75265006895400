import React from 'react';

export const bookOpen = 
<svg viewBox="-1 -1 2 2" height="100px" width="100px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
        <defs>
            <path id="HaskellSvgIcons-bookOpen-cover" d="M 0.0,0.75 L 0.95,0.75 L 0.95,-0.65 L 0.85,-0.65 M 0.0,0.75 Q 0.5,0.55 0.85,0.65 L 0.85,-0.7 L 0.75,-0.7 " fill="none" />
            <path id="HaskellSvgIcons-bookOpen-pages" d="M 0.0,0.75 Q 0.4,0.45 0.75,0.55 L 0.75,-0.75 Q 0.4,-1.0 0.0,-0.7 Z" strokeLinejoin="round" />
        </defs>
        <use xlinkHref="#HaskellSvgIcons-bookOpen-cover" />
        <use xlinkHref="#HaskellSvgIcons-bookOpen-cover" transform="matrix(-1,0,0,1,0,0)" />
        <use xlinkHref="#HaskellSvgIcons-bookOpen-pages" />
        <use xlinkHref="#HaskellSvgIcons-bookOpen-pages" transform="matrix(-1,0,0,1,0,0)" />
        <path d="M -7.0e-2,0.77 L 7.0e-2,0.77 L 7.0e-2,0.75 L -7.0e-2,0.75 Z" fill="none" strokeLinejoin="round" />
    </g>
</svg>

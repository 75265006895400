import React from "react";




const w = 1/11;


function calcHeightFromPosition(i) {
    
  let y = 0;

  switch (i) {
    case 0: y = 0.5 + 5 * w ; break;
    case 1: y = 0.5 + 3 * w ; break;
    case 2: y = 0.5 + 1 * w ; break;
    case 3: y = 0.5 - 1 * w ; break;
    case 4: y = 0.5 - 3 * w ; break;
    case 5: y = 0.5 - 5 * w ; break;
    default: y = 0; break;
  }

  return y
}


export function hexagramPath(usefulData, stroke) {

  function renderHexagramLine(symbol, i) {
    
    const y = calcHeightFromPosition(i);

    if (symbol === "|") {
      return `M  0  ${y}   L  1   ${y}`
    } else {
      return `M  0  ${y}   L  0.4  ${y}   M  0.6  ${y}   L  1  ${y}`
    }
  }

  return(
    <path
      strokeWidth = {w.toString()}
      fill="none"
      stroke={stroke}
      d={usefulData.map( renderHexagramLine ).join("   ")}
    />
  )
}





export function HexagramSvg(props){


  const withMargin = props.withMargin;
  const data = props.data;     // Must be a list of 6 numbers or a list of 6 "|", "=" symbols

  const onlyNumbers = data.every(x => typeof(x) === "number");

  const usefulData = 
    onlyNumbers ? data.map( (x,i) => (x % 2 === 1 ? "|" : "=") ) : data;



  function renderLineNumber(n, i) {

    const y = calcHeightFromPosition(i) + 0.008;

    return(
      <text 
        key={i}
        x="1.1" y={y} 
        fontSize="0.14"
        dominantBaseline="central"
        fontFamily="Courier New"
        fontWeight="bold"
        fill="currentColor"
      >
        {n}
      </text>
    )
  }



  return(
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox={ withMargin ? "0 -0.05 1.2 1.1" : "0 0 1 1"} 
      preserveAspectRatio="xMidYMid meet"
    >
      { hexagramPath(usefulData, "currentColor") }
      { ! onlyNumbers 
        ? <></>
        : data.map( renderLineNumber )
      }
    </svg>
  )
}
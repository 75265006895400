import React, { useContext } from "react";

import { Collapse     } from "core/components/Collapse/Collapse";
import { TrigramWheel } from "core/components/TrigramWheel/TrigramWheel";
import { LanguageContext } from "core/contexts/LanguageContext";



export function YiChingOracle(props) {

  const t = useContext(LanguageContext).t;
  


  return(
    <div className="YiChingOracle">
      <Collapse buttonWithLine={true}>
        <div className="YiChingOracle__textWrap">
          <div className="YiChingOracle__text">
            <p dangerouslySetInnerHTML={{__html: t("yiChingOracle_p1")}}>
            </p>
            <p dangerouslySetInnerHTML={{__html: t("yiChingOracle_p2")}}>
            </p>
          </div>
        </div>
      </Collapse>
      <TrigramWheel/>
    </div>
  )
}
/* eslint-disable @typescript-eslint/no-use-before-define */

import React from 'react';
import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';

import { en } from "assets/i18n/en";
import { es } from "assets/i18n/es";
import 
  { getCookie
  , setCookieForever
  } from "base/js/cookies";


// ---------------------------------------------------------------------------

const resources = 
  { es: 
    { translation: es
    }
  , en: 
    { translation: en
    }
  };

const initLanguage = () => {

  const langCookie = getCookie("ioracle-LanguageCookie");
  if (langCookie !== "") { return langCookie }

  const userLang = navigator.language;
  if (userLang.startsWith("es")) {
      return "es"
  } else {
      return "en"
  }
};

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
      { resources
      , lng: initLanguage()
      , interpolation: 
        { escapeValue: false // react already safes from xss
        }
      }
    );

// ---------------------------------------------------------------------------


export const LanguageContext = React.createContext();



export function LanguageProvider(props){

  const {t, i18n} = useTranslation(["translation"]);

  const changeLanguage = (langCode) => {
    setCookieForever("ioracle-LanguageCookie", langCode);
    i18n.changeLanguage(langCode);
  }

  return(
    <LanguageContext.Provider
      value={
        { t
        , currentLanguage : i18n.language
        , changeLanguage
        }
      }
    >
      {props.children}
    </LanguageContext.Provider>
  )
}